










































































































































































































































































































































































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import FormCheckbox from '@/components/form/form-checkbox.vue';
import operatingModes from '@/config/operating-modes';
import 'vue-select/dist/vue-select.css';
// import IRiskAssesment from '@/model/IRiskAssesment';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';
import errorMessage from '@/components/form/error-message.vue';
import { KlauselnArray } from '@/constant/klauselnArray';

  interface PriceItem {
  Id: string;
  Geltungsbereich: string;
  Versicherungssumme: number;
  Klauseln: string[];
  Selbstbehalt: string;
  Laufzeit: number;
}

type OperatingMode = {
  [key: string]: string;
};
const insuredItem = {
  subject: '',
  price: 0,
  isSelected: '',
  Klauseln: [],
  priceError: false,
};

export default Vue.extend({
  name: 'FairgroundInsurance',
  components: {
    FormCheckbox,
    FormSwitch,
    popUpModal,
    errorMessage,
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'insuranceData', 'isAPIObserver', 'AMSAPISendStatus'],
  watch: {
    $data: {
      handler() {
        // this.onNextClick(true);
      },
      deep: true,
    },
    totalPrice(val) {
      if (val > 2000000) {
        this.totalPriceError = true;
      } else {
        this.totalPriceError = false;
      }
    },
    insuranceData(val) {
      this.setValues({
        insuranceData: val,
      });
    },
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
    insuredItems: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      type: 'main',
      operatingModes,
      operatingMode: '',
      remarks: '',
      nextPath: '',
      processName: '',
      haveBussiness: false,
      haveTransport: false,
      insuredFields: insuredItem,
      insuredItems: [
        {
          ...insuredItem,
        },
      ],
      key: this.insuranceKey || '',
      Selbstbehalt: 'Klein',
      Klauseln: [],
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      totalPriceError: false,
      showCountryRelationsError: true,
      componentLoaded: false,
      mainPriceError: false,
      mainSubjectError: false,
    };
  },

  methods: {
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    checkPrice(key: number) {
      if (this.insuredItems[key].price > 1000000) {
        this.insuredItems[key].priceError = true;
        this.insuredItems[key].price = 1000000;
      } else {
        this.insuredItems[key].priceError = false;
      }
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    dublicateRow(fillData: boolean) {
      insuredItem.Klauseln = [];
      let item = JSON.parse(JSON.stringify(insuredItem));

      if (fillData && this.insuredItems.length > 0) {
        item = JSON.parse(JSON.stringify(this.insuredItems[this.insuredItems.length - 1]));
      }
      this.insuredItems.push({
        ...item,
      });
    },
    deleteRows() {
      const items = this.insuredItems.filter((item) => !item.isSelected);
      this.insuredItems = items;
    },
    onChange(path: string, key: string) {
      this.nextPath = path;
      this.key = key;
    },
    filterArray(testArray: (string | null)[]) {
      const result = testArray.filter((item) => item);
      return result;
    },
    onNextClick() {
      const priceData: PriceItem[] = [];

      if (this.insuredItems) {
        this.insuredItems.forEach((item) => {
          if (item.price > 0) {
            this.mainPriceError = false;
            priceData.push({
              Id: item.subject,
              Geltungsbereich: 'AngrenzendeLänder',
              Versicherungssumme: item.price,
              Klauseln: this.filterArray(item.Klauseln) as string[],
              Selbstbehalt: this.Selbstbehalt,
              Laufzeit: 1,
            });
          } else {
            this.mainPriceError = true;
          }
          if (item.subject === '') {
            this.mainSubjectError = true;
          } else {
            this.mainSubjectError = false;
          }
        });
      }
      if (this.mainPriceError || this.mainSubjectError) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      const data = this.getDataValues();
      const pData = {
        Zahlweise: 'Vierteljährlich',
        Risiken: priceData,
      };

      this.$emit('risk-assesment-change', {
        remarks: this.remarks,
        haveTransport: this.haveTransport,
        haveBussiness: this.haveBussiness,
      });
      this.$emit(
        'data-changed',
        '03603ad4-f35b-425a-8f87-bce69a4f5700',
        pData,
        data,
        'FAIRGROUND_INSURANCE',
      );
    },
    getDataValues() {
      const data = {
        processName: this.processName,
        insuredItems: this.insuredItems,
        totalPrice: this.totalPrice,
        Selbstbehalt: this.Selbstbehalt,
        haveBussiness: this.haveBussiness,
        haveTransport: this.haveTransport,
        remarks: this.remarks,
      };
      return data;
    },

    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    setValues(formData: any) {
      if (formData.insuranceData.processName) {
        this.processName = formData.insuranceData.processName;
      } else if (typeof formData.assesment !== 'undefined') {
        this.processName = formData.assesment.processName;
      }
      if (formData.insuranceData.insuredItems) {
        this.insuredItems = formData.insuranceData.insuredItems;
      }
      if (formData.insuranceData.Selbstbehalt) {
        this.Selbstbehalt = formData.insuranceData.Selbstbehalt;
      }
      if (formData.insuranceData.haveBussiness) {
        this.haveBussiness = formData.insuranceData.haveBussiness;
      }
      if (formData.insuranceData.haveTransport) {
        this.haveTransport = formData.insuranceData.haveTransport;
      }
      if (formData.insuranceData.remarks) {
        this.remarks = formData.insuranceData.remarks;
      }
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('red-border')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
  },
  computed: {
    changeData(): any {
      return [this.haveBussiness, this.haveTransport, this.remarks, this.processName, this.Selbstbehalt, this.insuredItems, this.totalPrice].join();
    },
    isUsedWordTent() {
      let returnVal = false;

      if (this.insuredItems) {
        this.insuredItems.forEach((item) => {
          if (item.subject.toLowerCase().includes('zelt')) {
            returnVal = true;
          }
        });
      }
      return returnVal;
    },
    isSameSubject() {
      const subjectArr: string[] = [];
      let returnVal = false;

      if (this.insuredItems) {
        this.insuredItems.forEach((item) => {
          if (subjectArr.includes(item.subject)) {
            returnVal = true;
          }
          subjectArr.push(item.subject);
        });
      }

      return returnVal;
    },
    getKlauseln() {
      return KlauselnArray;
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    totalPrice(): number {
      if (this.insuredItems) {
        return this.insuredItems.reduce((acc, item) => acc + item.price, 0);
      }
      return 0;
    },
  },

  mounted() {
    if (this.$attrs.formData) {
      this.setValues(this.$attrs.formData);
    } else if (this.insuranceData) {
      const formData = {
        assesment: this.assesment,
        insuranceData: this.insuranceData,
      };
      this.setValues(formData);
    }
    this.$emit(
      'risk-assesment-change',
      {
        operatingMode: this.operatingMode,
      },
      'FAIRGROUND_INSURANCE',
    );
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
