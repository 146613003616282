export const KlauselnArray = [
  {
    title: 'Sturm und Hagel',
    value: 'SturmHagel',
  },
  {
    title: 'Leitungswasser',
    value: 'Leitungswasser',
  },
  {
    title: 'Einbruchdiebstahl/Raub',
    value: 'EdRaub',
  },
  {
    title: 'Schwerer Diebstahl/Raub',
    value: 'DiebstahlRaub',
  },
  {
    title: 'Streichung Abzug „neu für alt“',
    value: 'StreichungAbzug',
  },
  {
    title: 'Bergung- und Beseitigung',
    value: 'BergungBeseitigung',
  },
];
export default null;
